import axios from "axios";
import qs from "qs";

export const getTokenApim = () => {
  const data = {
    client_id: process.env.REACT_APP_APIM_CLIENT_ID,
    client_secret: process.env.REACT_APP_APIM_CLIENT_SECRET,
    grant_type: "password",
    username: process.env.REACT_APP_APIM_USER,
    password: process.env.REACT_APP_APIM_PASSWORD,
  };
  let config: any = {
    method: "post",
    url: process.env.REACT_APP_APIM_GET_TOKEN,
    headers: {
      "API-Key": process.env.REACT_APP_APIM_GET_TOKEN_KEY,
      Authorization: "Basic " + process.env.REACT_APP_APIM_GET_TOKEN_AUTHO,
    },
    data: qs.stringify(data),
  };

  return axios(config);
};

export const getClosures = async (email: string) => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const apimToken = tokenResponse.data.access_token;

    // Configurar la solicitud para obtener los cierres con el token
    let config: any = {
      method: "get",
      url: process.env.REACT_APP_APIM + "/cierre",
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        email: email,
        Authorization: "Bearer " + apimToken, // Agregar el token de acceso
      },
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al obtener el token:", error);
    throw error;
  }
};

export const getHistory = async (email: string) => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const token = tokenResponse.data.access_token;

    // Configurar la solicitud para obtener el historial con el token
    let config: any = {
      method: "get",
      url: process.env.REACT_APP_APIM + "/mesesHistorico",
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        Authorization: "Bearer " + token, // Agregar el token de acceso
        email: email,
      },
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al obtener el historial:", error);
    throw error;
  }
};

export const getMonth = async () => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const token = tokenResponse.data.access_token;

    // Configurar la solicitud para obtener el mes con el token
    let config: any = {
      method: "get",
      url: process.env.REACT_APP_APIM + "/mesesModificar",
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        Authorization: "Bearer " + token, // Agregar el token de acceso
      },
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al obtener el mes:", error);
    throw error;
  }
};

export const getTypes = async (email: string) => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const token = tokenResponse.data.access_token;

    // Configurar la solicitud para obtener los tipos con el token
    var config: any = {
      method: "get",
      url: process.env.REACT_APP_APIM + "/tipos",
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        Authorization: "Bearer " + token, // Agregar el token de acceso
        email: email,
      },
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al obtener los tipos:", error);
    throw error;
  }
};

export const getPrinexDate = async (horas: any) => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const token = tokenResponse.data.access_token;

    // Configurar la solicitud para obtener la fecha de Prinex con el token
    var config: any = {
      method: "get",
      url: process.env.REACT_APP_APIM + "/horasCortes/" + horas,
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        Authorization: "Bearer " + token, // Agregar el token de acceso
      },
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al obtener la fecha de Prinex:", error);
    throw error;
  }
};

export const addClosure = async (data: any) => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const token = tokenResponse.data.access_token;

    // Configurar la solicitud para agregar el cierre con el token
    var config: any = {
      method: "post",
      url: process.env.REACT_APP_APIM + "/ejecucion",
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        Authorization: "Bearer " + token, // Agregar el token de acceso
        "Content-Type": "application/json",
      },
      data: data,
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al agregar el cierre:", error);
    throw error;
  }
};

export const updateClosure = async (data: any) => {
  try {
    // Obtener el token
    const tokenResponse = await getTokenApim();
    const token = tokenResponse.data.access_token;

    // Configurar la solicitud para actualizar el cierre con el token
    var config: any = {
      method: "post",
      url: process.env.REACT_APP_APIM + "/actualizaCierre",
      headers: {
        "API-Key": process.env.REACT_APP_APIM_KEY,
        Authorization: "Bearer " + token, // Agregar el token de acceso
        "Content-Type": "application/json",
      },
      data: data,
    };

    // Realizar la solicitud y devolver el resultado
    return axios(config);
  } catch (error) {
    // Manejar errores
    console.error("Error al actualizar el cierre:", error);
    throw error;
  }
};
