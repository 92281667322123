import { FormatLineSpacing } from "@mui/icons-material";
import { Autocomplete, Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import {
  addClosure,
  getClosures,
  getMonth,
  getPrinexDate,
  getTypes,
} from "../../Services/ClosureService/ClosureService";
import AedasLoading from "../AedasLoading/AedasLoading";
import CustomModal from "../ModalCustom/CustomModal";
import { log } from "console";
import { usePermission } from "../../zustand/usePermission";

function NewClosureForm(props: any) {
  const { edit, data, setValue } = props;
  const user = useSelector((state: any) => state.userReducer.user?.user);
  const permission = usePermission((state: any) => state?.permission);
  const [month, isFetchinMonth] = useFetch(getMonth);
  const [types, isFetchinTypes] = useFetch(getTypes, user?.email);

  const [closures, isFetchingClousures] = useFetch(getClosures, user?.email);
  const isLoading = useIsLoading([
    isFetchinTypes,
    isFetchinMonth,
    isFetchingClousures,
  ]);
  const [openModal, setopenModal] = useState(false);
  const [prinexDate, setprinexDate] = useState<any>([]);
  const [isLoadingPrinex, setisLoadingPrinex] = useState(true);
  const history = useHistory();
  const firstUpdate = useRef(true);
  const [initialPrinexDate, setInitialPrinexDate] = useState<any>(null);
  const [initialFechaEjecucion, setInitialFechaEjecucion] = useState<any>(null);
  console.log(types);

  const [error, setError] = useState(false);
  const handleSend = (values: any) => {
    console.log(initialFechaEjecucion);
    console.log(initialPrinexDate);

    const data = {
      fechaCierre: values.month,
      departamento: values.module.label,
      tipo: values.type["Tipo de cierre"],
      Email: user?.email,
      fechaEjecucion_old: initialFechaEjecucion, // Utiliza los valores iniciales
      copiaPrinex_old: initialPrinexDate, // Utiliza los valores iniciales
      fechaEjecucion: moment(
        values.prinex_date.label,
        "DD-MM-YYYY HH:mm"
      ).format("YYYY-MM-DD"),
      copiaPrinex: moment(values.prinex_date.label, "DD-MM-YYYY HH:mm").format(
        "HH:mm"
      ),
      modificadoPor: user.display_name,
    };

    addClosure(data)
      .then(() => {
        toast.success("Añadido");
        if (!edit) {
          history.push("/");
        } else {
          history.push("/");
          props.setOpen(false);
        }
      })
      .catch(() => {
        toast.error("Ha habido un error");
      });
  };
  const formik = useFormik({
    initialValues: {
      month: data?.month ?? null,
      module: data?.module ?? null,
      type: data?.type ?? null,
      prinex_date: data?.prinex_date ?? null,
    },
    validationSchema: null,
    onSubmit: (values: any) => {
      if (edit) {
        handleSend(values);
      } else {
        if (
          permission == "CG" &&
          closures[0] &&
          closures[0]?.fechas?.find(
            (date: any) =>
              date.FechaCierre === values.month &&
              date.Departamento === values.module.label &&
              date.Tipo === values.type["Tipo de cierre"]
          )
        ) {
          setopenModal(true);
        } else {
          handleSend(values);
        }
      }
    },
  });
  useEffect(() => {
    if (setValue) {
      setValue(formik.values.module);
    }
  }, [formik.values.module]);
  useEffect(() => {
    setisLoadingPrinex(true);
    if (!edit) {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
    }

    if (edit) {
      getPrinexDate(formik.values.month).then((response: any) => {
        setError(false);
        setisLoadingPrinex(false);
        setprinexDate(response?.data?.fechas);
      });
    } else {
      if (month?.fechas?.find((fecha: any) => fecha === formik.values.month)) {
        getPrinexDate(formik.values.month).then((response: any) => {
          setError(false);
          setisLoadingPrinex(false);
          setprinexDate(response?.data?.fechas);
        });
      } else {
        setError(true);
      }
    }

    formik.values.prinex_date = null;
    formik.setFieldValue("prinex_date", null);
  }, [formik.values.month]);
  useEffect(() => {
    if (!edit) {
      formik.values.type = null;
      formik.setFieldValue("type", null);
    }
  }, [formik.values.module]);
  useEffect(() => {
    if (edit) {
      setInitialPrinexDate(data?.ejecucion_date);
      setInitialFechaEjecucion(data?.copia_date);
    }
  }, []);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      {" "}
      <CustomModal
        size="lg"
        show={openModal}
        onChange={() => {
          setopenModal(!openModal);
        }}
        title="Confirmación"
        body={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ padding: "10px", background: "white" }}>
              Ya existe un cierre programado ¿Desea sobrescribirlo?
            </p>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <Button
                onClick={() => setopenModal(false)}
                style={{ color: "white", background: "var(--aedas-red)" }}
              >
                Rechazar
              </Button>
              <Button
                onClick={() => handleSend(formik.values)}
                style={{ color: "white", background: "var(--aedas-green)" }}
              >
                Confirmar
              </Button>
            </div>
          </div>
        }
      ></CustomModal>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              width: "50%",
              padding: "1rem",
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <div style={{ width: "100%" }}>
              <TextField
                disabled={edit ? true : false}
                fullWidth
                id="month"
                label="Mes"
                variant="outlined"
                type="month"
                InputLabelProps={{ shrink: true, required: true }}
                InputProps={{
                  inputProps: {
                    min: month?.fechas[0],
                    max: month?.fechas[month.fechas.length - 1],
                  },
                }}
                value={formik.values.month}
                onChange={formik.handleChange}
                error={error}
                helperText={formik.errors.month}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Autocomplete
                disabled={edit ? true : formik.values.module ? false : true}
                fullWidth
                style={{ width: "100%" }}
                id="type"
                options={types.tipos?.filter(
                  (type: any) =>
                    //@ts-ignore
                    type["Módulo"] === formik?.values?.module?.label ?? ""
                )}
                value={formik.values.type}
                onChange={(e, value) => {
                  formik.setFieldValue("type", value);
                }}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option["Tipo de cierre"] === value["Tipo de cierre"];
                }}
                sx={{ width: 300 }}
                getOptionLabel={(value: any) => value["Tipo de cierre"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Módulo"
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                )}
              />
            </div>
          </div>
          <div
            style={{
              width: "50%",
              padding: "1rem",
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <div style={{ width: "100%" }}>
              <Autocomplete
                fullWidth
                disabled={
                  edit
                    ? true
                    : error
                    ? true
                    : formik.values.month
                    ? false
                    : true
                }
                style={{ width: "100%" }}
                id="module"
                options={[
                  { id: 1, label: "Anaplan" },
                  { id: 2, label: "Power BI: Informe mensual" },
                ]}
                defaultValue={formik.values.module}
                onChange={(e, value) => {
                  formik.setFieldValue("module", value);
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.label === value?.label
                }
                sx={{ width: 300 }}
                getOptionLabel={(value: any) => {
                  return value.label2 ?? value?.label;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Aplicación"
                    error={
                      formik.touched.module && Boolean(formik.errors.module)
                    }
                    helperText={formik.touched.module && formik.errors.module}
                  />
                )}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Autocomplete
                fullWidth
                loading={isLoadingPrinex}
                disabled={edit ? false : formik.values.type ? false : true}
                style={{ width: "100%" }}
                id="prinex_date"
                options={
                  prinexDate
                    ? prinexDate?.map((date: any, i: number) => {
                        return {
                          id: i,
                          label:
                            moment(date["Fecha Ejecucion"]).format(
                              "DD-MM-YYYY"
                            ) +
                            " " +
                            date["copia_prinex"],
                        };
                      })
                    : []
                }
                value={formik.values.prinex_date}
                onChange={(e, value) => {
                  formik.setFieldValue("prinex_date", value);
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.label === value?.label
                }
                sx={{ width: 300 }}
                getOptionLabel={(value: any) => value?.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Fecha copia"
                    error={
                      formik.touched.prinex_date &&
                      Boolean(formik.errors.prinex_date)
                    }
                    helperText={
                      formik.touched.prinex_date && formik.errors.prinex_date
                    }
                  />
                )}
              />
            </div>
          </div>

          <div style={{ width: "100%", padding: "1rem" }}>
            <Button
              disabled={formik.values.prinex_date ? false : true}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              style={{
                backgroundColor: formik.values.prinex_date
                  ? "var(--blue-lis)"
                  : "grey",
              }}
            >
              {!edit ? "Crear nuevo cierre" : "Actualizar"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewClosureForm;
